import React from 'react';

// Components
import Layout from '../../components/layout';
import WhatToExpect from '../../components/resources/what-to-expect';

import routes from '../../constants/routes';

const WhatToExpectPage = () => {
    return (
        <Layout slug={routes.what_to_expect} title="What to Expect">
            <WhatToExpect />
        </Layout>
    );
};

export default WhatToExpectPage;
