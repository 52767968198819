import React from 'react';

import * as styles from '../../styles/modules/what-to-expect.module.scss';

const WhatToExpect = () => {
    return (
        <div className={styles.whatToExpect}>
            <div className="copy-width">
                <h1>What To Expect</h1>
                <p>Managing expectations are integral to the building process. At Alouf Custom Builders, we try to always be clear and precise in our communications so that you, as the client, is aware of any delays or issues we may be experiencing.</p>
                <h2>Custom Home Cost Factors By Style</h2>
                <p>We often get asked whether it's less expensive to build a two-story house over a one-story house with the same amount of living space. The answer to that is a resounding yes, and here's why:</p>
                <blockquote>
                    <p>A one-story home with 2,000 square feet of finished living space has a foundation under the entire 2,000 square feet &ndash; and a roof above it! If you added another story, the living space increases to 4,000 square feet, but the roof and foundation stay the same. The only difference is the addition of the second story, which is much less expensive when the foundation and roof are already present.</p>
                    <p>So, a two-store home with 2,000 square feet of living space &ndash; 1,000 on the main level and 1,000 upstairs &ndash; has a smaller foundation and roof, typically resulting in a lower cost to construct per square foot.</p>
                    <p>Some of the cost are constant regardless of the size of the home. Government fees &ndash; such as utility hook-up fees &ndash; are typically unaffected by the size of the home. As a general rule, smaller homes have a higher cost-per-square-foot, assuming comparable material prices. This is especially true when building a home with fewer than 2,000 square feed, as the static construction costs remain with less living space by which to divide the cost.</p>
                </blockquote>
                <h2>Cost is Driven by Design</h2>
                <p><em>Design has a strong bearing on a home's cost.</em></p>
                <p>The elements that create curb appeal &ndash; such as offsets, high roof lines, large windows, grand entryways, and accent materials &ndash; can have a high price tag without changing the amount of living space in the home.</p>
                <blockquote>
                    <p>Inside, there are countless variables to consider that could impact the overall cost of the home. Ceilings, for example &ndash; whether vaulted, offered, or simply tall &ndash; can add more cost to a home than a flat ceiling of standard height. Two-story rooms, such as foyers and great rooms, can also drive up cost. Whether these additions may make for a <em>grander</em> home, they do not increase the <em>livable</em> space of the home.</p>
                </blockquote>
                <p>The cost of your home plans is another consideration. With so many online sites offering blueprints for download, you may think that is the economical way to go; however, if you wish to make any changes to the plan, those sites can become very costly. It is best to work with a local draftsman or architect to design your home.</p>
                <h2>What is Included in the Price?</h2>
                <p>As with many things, not all custom builders will consider the same items as "standard."</p>
                <blockquote>
                    <p>Builder A may price hardwood flooring in the main area but carpet in the bedrooms.</p>
                    <p>Builders B and C may both price hardwood throughout the entire home, but then the question becomes whether it is pre-finished or nailed-down and finished in-place.</p>
                    <p>Builder A may say the granite countertops are included in the price. Builder B gives an allowance for granite countertops. To compare, you need to know the square footage of countertop and the price-per-foot, the edge profile, and the thickness that builder A has included</p>
                    <p>Builder A may include base trim at 3&frac14; inches high and do a picture frame around the windows as standard. Builder B may have a standard trim package of 5&frac14;" base and trim out the windows with a stoop and seal.</p>
                </blockquote>
                <p>You may wonder:</p>
                <ul>
                    <li>"Can I have more than one color of paint?"</li>
                    <li>"Is the paint flat or semi-gloss?"</li>
                    <li>"What is the cost difference?"</li>
                    <li>"Does the contract include landscaping?"</li>
                    <li>"Sod? Seed? Neither?"</li>
                    <li>Do I get a gravel driveway, or paved?"</li>
                    <li>"Will there even <em>be</em> a driveway?"</li>
                </ul>
                <p>You're laughing now, but if you don't ask these questions, you may assume incorrectly and be in for quite the big surprise. Not all builders include the same items as standard in their contracts, and these things can very quickly add up to a large sum of money that you weren't expecting to spend!</p>
                <h2>Home Site Improvement and Land Cost</h2>
                <p>Many custom homes are built on individual properties. The expense to improve or complete the land development can drastically impact your build cost.</p>
                <blockquote>
                    <p>Engineering and surveying, driveways, power and communication lines, tree clearing, and well and septic installation can each individually cost thousands of dollars. Some builders include the cost of all aspects of this in their contract, while other contractors put some of these responsibilities on the owners.</p>
                </blockquote>
                <p><strong>The bottom line: be very careful when you are interviewing builders for your new home project. It can be difficult to get "apples-to-apples comparisons if you don't know what to ask.</strong></p>
            </div>
        </div>
    );
};

export default WhatToExpect;
